import type { ComponentProps } from "react"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { Image, type ImageProps } from "~/components/ui/Image"
import type { LinkProps } from "~/components/ui/Link"

import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 5 }, { ratio: 1 }]

export type TByCategoryItem = {
  title?: string
  image?: Nullish<ImageProps>
  link?: Nullish<LinkProps>
} & RecipeVariants<typeof css.title>

type TByCategoryItemProps = PropsWithClassName<TByCategoryItem & ComponentProps<"div">>

function ByCategoryItem({ className, title, image, isActive, font, ...props }: TByCategoryItemProps) {
  return (
    <div className={clsx(className, css.ByCollectionItem)} {...props}>
      {image && (
        <Image {...image} className={clsx(css.image({ isActive }))} ratio="720_1000" sizesFromBreakpoints={SIZES} />
      )}
      <h2 className={css.title({ isActive, theme: getTheme(isActive), font })}>{title}</h2>
    </div>
  )
}

export default ByCategoryItem

function getTheme(isActive: TByCategoryItemProps["isActive"]): NonNullable<RecipeVariants<typeof css.title>>["theme"] {
  return isActive ? "white" : "black"
}
