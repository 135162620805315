"use client"

import { useEffect, useMemo, useState, type PropsWithChildren } from "react"
import clsx from "clsx"
import { AnimatePresence, m } from "framer-motion"

import { useIsHover } from "@unlikelystudio/react-hooks"

import { getProductColorByValue } from "~/lib/shopify/utils/get-product-color-by-value"
import serializePrice from "~/data/price/serializer"
import useLocale from "~/hooks/useLocale"
import { FillImage } from "~/components/ui/FillImage"
import { Image } from "~/components/ui/Image"
import type { TLinkedProductsCard } from "~/components/ui/LinkedProductsCard/_data/serializer"
import { PRODUCT_CARD_IMAGE_SIZES } from "~/components/ui/ProductCard"
import * as productCardCss from "~/components/ui/ProductCard/styles.css"
import { LinkedProducts } from "~/components/ui/ProductHeader/components/ProductDetails/components/LinkedProducts"
import ProductVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector"
import { useProductsBundleContext } from "~/components/slices/ProductsBundle/providers/ProductsBundleProvider"
import numberAsBoolean from "~/utils/number-as-boolean"

import { bezierCurves } from "~/styles/variables/bezier-curves"
import { text } from "~/styles/variants"

import * as componentCss from "./styles.css"

type LinkedProductsCardProps = PropsWithChildren<{ products: TLinkedProductsCard[]; index?: number }>

const css = { ...productCardCss, ...componentCss }

function ClientLinkedProductsCard({ products, index }: LinkedProductsCardProps) {
  const locale = useLocale()
  const { setActiveVariants, setActiveProducts } = useProductsBundleContext()
  const [activeProductIndex, setActiveProductIndex] = useState<number>(0)
  const activeProduct = products[activeProductIndex] ?? products?.[0]
  const [activeVariantIndex, setActiveVariantIndex] = useState<number>(
    activeProduct?.variants?.findIndex((variant) => variant.id === activeProduct?.defaultVariant?.id) ?? 0
  )

  const [isHover, callbacks] = useIsHover()

  const activeVariant = activeProduct?.variants?.[activeVariantIndex] ?? null

  const price = serializePrice(
    locale,
    `${products[activeProductIndex]?.variants?.[activeVariantIndex]?.price?.currencyCode}`,
    products[activeProductIndex]?.variants?.[activeVariantIndex]?.price?.amount
  )
  useEffect(() => {
    // If the active variant is not available, we reset it to the first one
    // Say if last activeProductIndex was 5 and the next product has only 2 variants
    if (!products?.[activeProductIndex]?.variants?.[activeVariantIndex]) {
      setActiveVariantIndex(0)
    }
  }, [activeProductIndex])

  useEffect(() => {
    if (!activeVariant || !numberAsBoolean(index) || index === undefined || !activeProduct) return
    setActiveVariants?.((prev) => {
      const newVariants = [...prev]
      newVariants[index] = activeVariant
      return newVariants
    })

    setActiveProducts?.((prev) => {
      const newProducts = [...prev]
      newProducts[index] = activeProduct
      return newProducts
    })
  }, [activeVariantIndex, activeProductIndex])

  const colors = useMemo(
    () =>
      products
        ?.map((product) => {
          const label = product?.color?.value

          if (!label) return null
          const hexa = product?.color?.hexa ?? getProductColorByValue(label)
          return {
            link: null,
            value: label,
            hexa,
          }
        })
        .filter(Boolean)
        .map((product, index) => ({ ...product, active: index === activeProductIndex })),
    [activeProductIndex]
  )

  if (!products) return null

  const { image, hoverImage, title } = activeProduct ?? {}

  return (
    <article className={clsx(css.ProductCard, css.ProductCardWithLinkedProducts)}>
      {image && (
        <div className={clsx(css.mediasContainer)} {...callbacks}>
          <Image
            imageClassName={css.image}
            {...image}
            asPlaceholder
            ratio={"74_110"}
            sizesFromBreakpoints={PRODUCT_CARD_IMAGE_SIZES.DEFAULT}
          />
          <AnimatePresence>
            {hoverImage && isHover && (
              <m.div
                className={clsx(css.hoverImage)}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.25, ease: bezierCurves["easeOutQuart"] },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.25, ease: bezierCurves["easeOutQuart"] },
                }}
              >
                <FillImage {...hoverImage} sizesFromBreakpoints={PRODUCT_CARD_IMAGE_SIZES.DEFAULT} />
              </m.div>
            )}
          </AnimatePresence>
        </div>
      )}
      <div className={clsx(css.textualContent)}>
        <span
          className={clsx(text({ design: "body-neue-12", color: "black", textTransform: "uppercase" }))}
          {...callbacks}
        >
          {title} - {price}
        </span>
        {colors?.length > 1 && (
          <LinkedProducts labelPosition="bottom" colors={colors} onClick={(index) => setActiveProductIndex(index)} />
        )}
        {activeProduct?.variants && (
          <ProductVariantsSelector
            onChange={(index) => setActiveVariantIndex(index ?? 0)}
            variants={activeProduct?.variants}
            activeVariant={activeVariant}
          />
        )}
      </div>
    </article>
  )
}

export { ClientLinkedProductsCard }
