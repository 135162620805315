"use client"

import Script from "next/script"
import { clsx } from "clsx"

import { isDarel } from "~/lib/brand"
import Spinner from "~/components/ui/Spinner"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

declare global {
  interface Window {
    __stockist_reload(): void
  }
}

export type TStoreLocator = {
  locale: string
}
export type StoreLocatorProps = SliceWithProps<TStoreLocator>

function StoreLocator({ className, locale }: StoreLocatorProps) {
  const handleOnReady = () => {
    window?.__stockist_reload?.()
  }

  return (
    <div data-comp="Slices/StoreLocator" className={clsx(className, css.StoreLocator)}>
      <div className={clsx(css.container)}>
        <div data-stockist-widget-tag={isDarel ? "u8239" : "u9663"} data-stockist-lang={locale}>
          <Spinner />
        </div>
      </div>

      <Script
        id="e-reservation"
        strategy="lazyOnload"
        onReady={handleOnReady}
        dangerouslySetInnerHTML={{
          __html: `(function(s,t,o,c,k){c=s.createElement(t);c.src=o;c.async=1;
        k=s.getElementsByTagName(t)[0];k.parentNode.insertBefore(c,k);
        })(document,'script','//stockist.co/embed/v1/widget.min.js');`,
        }}
      />
    </div>
  )
}

export default StoreLocator
