"use client"

import { useRef, type ComponentProps } from "react"
import { assignInlineVars } from "@vanilla-extract/dynamic"
import { clsx } from "clsx"

import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import type { Nullish } from "~/@types/generics"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import InlineCta from "~/components/ui/InlineCta"
import RichText from "~/components/ui/RichText"
import ByCategoryItem, { type TByCategoryItem } from "~/components/slices/ByCategoryBlock/ByCategoryItem"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

export type TByCollection = {
  title?: SbRichtext
  ctaText?: Nullish<string>
  categories: TByCategoryItem[]
  backgroundColor?: string
}

export type ByCollectionProps = SliceWithProps<ComponentProps<"div"> & TByCollection>

function ByCollection({ className, title, ctaText, categories, backgroundColor, isFirstSlice }: ByCollectionProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [{ slideIndex, setSlideIndex }, setSliderState] = useSliderState()

  return (
    <div
      data-comp="Slices/ByCollection"
      ref={containerRef}
      className={clsx(className, css.ByCollection(), isFirstSlice ? css.firstSliceMargin : null)}
      style={assignInlineVars({ [css.backgroudColor]: backgroundColor })}
    >
      {title && <RichText className={clsx(css.title)} disableStyles render={title} />}
      <Slider
        className={clsx(css.slider)}
        setSliderState={setSliderState}
        customSliderRef={containerRef}
        snap
        infinite
        maxSlideIndexChange={1}
      >
        {categories?.map(({ title, image, font }, index) => (
          <ByCategoryItem
            key={`by_category_item_${index}`}
            className={css.item}
            isActive={index === slideIndex}
            onClick={() => setSlideIndex(index)}
            title={title}
            image={image}
            font={font}
          />
        ))}
      </Slider>
      {categories?.[slideIndex]?.link && (
        <InlineCta className={css.cta} color="current" size="none" withDefaultLine {...categories?.[slideIndex]?.link}>
          {ctaText}
        </InlineCta>
      )}
    </div>
  )
}

export default ByCollection
