import { useEffect, useMemo, useRef, useState, type DependencyList } from "react"
//@ts-ignore
import fit from "math-fit"
import { useElementSize } from "usehooks-ts"

const TYPES = ["contain", "cover"] as const

interface UseMathFitParams {
  type: (typeof TYPES)[number]
  timeout?: number
}

interface IMathFit {
  left?: number
  top?: number
  width?: number
  height?: number
  scale?: number
}

interface useMathFitProps {
  parentRef: (node: HTMLElement | null) => void
  targetRef: (node: HTMLElement | null) => void
  processed: IMathFit
}

export function useMathFit({ type, timeout = 0 }: UseMathFitParams, dep: DependencyList): useMathFitProps {
  const [parentRef, parentBounds] = useElementSize<HTMLElement>()
  const [targetRef, targetBounds] = useElementSize<HTMLElement>()
  const [processed, setProcessed] = useState<IMathFit>({})

  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (targetBounds && parentBounds) {
      timeoutId.current = setTimeout(() => {
        setProcessed(fit[type](targetBounds, parentBounds))
      }, timeout)
    }

    return () => {
      timeoutId.current && clearTimeout(timeoutId.current)
    }
  }, [targetBounds, parentBounds, type, ...dep])

  if (!TYPES.includes(type)) {
    console.warn(`[useMathFit] Please use one of this ${TYPES.join(" | ")} as type`)
    //@ts-ignore
    return null
  }

  return { parentRef, targetRef, processed }
} /* In the `useMathFitStyle` function, `params` is an object that
contains the parameters for the `useMathFit` hook. These parameters
include the `type` of math fit calculation to perform (either
"contain" or "cover") and an optional `timeout` value. The `params`
object is passed as an argument to the `useMathFit` hook to
calculate the math fit values. */

export function useMathFitStyle(params: UseMathFitParams, dep: DependencyList) {
  const { parentRef, targetRef, processed } = useMathFit(params, dep)

  return useMemo(
    () => ({
      parentRef,
      targetRef,
      processed: {
        transform: `translate3d(${processed.left}px, ${processed.top}px, 0)`,
        width: `${processed.width}px`,
        height: `${processed.height}px`,
      },
    }),
    [parentRef, targetRef, processed]
  )
}
