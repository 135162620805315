"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import type { TMediaShopTheLookItem } from "~/components/ui/MediaShopTheLookItem"
import * as css from "~/components/ui/MediaShopTheLookItem/styles.css"
import ShopTheLookPanel from "~/components/ui/Panels/ShopTheLook"
import SquareCta from "~/components/ui/SquareCta"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

type MediaShopTheLookCtaClientProps = {
  shouldEnable: Nullish<boolean>
  verticalPosition?: TMediaShopTheLookItem["verticalPosition"]
  horizontalPosition?: TMediaShopTheLookItem["horizontalPosition"]
  gids?: TMediaShopTheLookItem["shopTheLookGids"]
} & ComponentProps<"div">

export default function MediaShopTheLookCtaClient({
  shouldEnable,
  verticalPosition = "bottom",
  horizontalPosition = "right",
  gids,
  className,
}: MediaShopTheLookCtaClientProps) {
  const t = useTranslate()
  const { add } = usePanel()

  return shouldEnable ? (
    <div className={clsx(css.SquareCta({ verticalPosition, horizontalPosition }), className)}>
      <SquareCta
        onClick={() => {
          gids && add(<ShopTheLookPanel gids={gids} />)
        }}
        theme="backgroundWhite"
      >
        {t("shop_the_look")}
      </SquareCta>
    </div>
  ) : null
}
