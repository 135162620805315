import type { ComponentType } from "react"
import dynamic from "next/dynamic"

import type { ExplicitAny } from "~/@types/generics"
import type { SbSchemaOptions } from "~/lib/storyblok/schemas"
import { sbBlocksSchema } from "~/lib/storyblok/schemas/default/blocks"
import {
  sbSliceSizeGuideImageSchema,
  type SbSizeGuideImage,
} from "~/lib/storyblok/schemas/library/size-guide-slices/size_guide_image"
import {
  sbSliceSizeGuideParagraphSchema,
  type SbSizeGuideParagraph,
} from "~/lib/storyblok/schemas/library/size-guide-slices/size_guide_paragraph"
import {
  sbSliceSizeGuideTableSchema,
  type SbSizeGuideTable,
} from "~/lib/storyblok/schemas/library/size-guide-slices/size_guide_table"
import {
  sbSliceSizeGuideTitleSchema,
  type SbSizeGuideTitle,
} from "~/lib/storyblok/schemas/library/size-guide-slices/size_guide_title"

export type sbSizeGuideSliceSchema = SbSizeGuideImage | SbSizeGuideTable | SbSizeGuideParagraph | SbSizeGuideTitle

type SbSizeGuideSliceName = NonNullable<sbSizeGuideSliceSchema>["name"]

export const sbSizeGuideSliceComponents = {
  size_guide_image: dynamic(() => import("~/components/slices/SizeGuideSlice/SizeGuideImage")),
  size_guide_table: dynamic(() => import("~/components/slices/SizeGuideSlice/SizeGuideTable")),
  size_guide_paragraph: dynamic(() => import("~/components/slices/SizeGuideSlice/SizeGuideParagraph")),
  size_guide_title: dynamic(() => import("~/components/slices/SizeGuideSlice/SizeGuideTitle")),
} satisfies Record<SbSizeGuideSliceName, ComponentType<ExplicitAny>>

export function sbSizeGuideSlicesSchema(options: SbSchemaOptions) {
  return sbBlocksSchema([
    sbSliceSizeGuideParagraphSchema(),
    sbSliceSizeGuideTitleSchema(),
    sbSliceSizeGuideImageSchema(options),
    sbSliceSizeGuideTableSchema(),
  ])
}
