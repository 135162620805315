"use client"

import { useRouter } from "next/navigation"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import { FillImage } from "~/components/ui/FillImage"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import InlineCta from "~/components/ui/InlineCta"
import type { TLinkLabel } from "~/components/ui/Link/_data/serializer"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

const SIZES = [{ ratio: 1 }]

export type TBannerWithLink = SliceWithProps<
  {
    image: TImage
    mobileImage: TImage
    link: Nullish<TLinkLabel>
  } & RecipeVariants<typeof css.BannerWithLink>
>
export default function BannerWithLink({ image, mobileImage, link, theme, isFirstSlice }: TBannerWithLink) {
  const router = useRouter()
  const hasLink = Boolean(link)

  return (
    <section
      data-comp="Slices/BannerWithLink"
      className={clsx(css.BannerWithLink({ theme, hasLink }), { [css.isFirstSlice]: isFirstSlice })}
      {...(link
        ? {
            onClick: () => {
              router.push(link.href)
            },
          }
        : {})}
    >
      <FillImage
        className={clsx(css.image, sprinkles({ display: { mobile: "none", desktop: "block" } }))}
        {...image}
        sizesFromBreakpoints={SIZES}
        priority={isFirstSlice}
      />

      <FillImage
        className={clsx(css.image, sprinkles({ display: { mobile: "block", desktop: "none" } }))}
        priority={isFirstSlice}
        {...mobileImage}
        sizesFromBreakpoints={SIZES}
      />
      {link && <InlineCta color="current" withLine className={css.cta} {...link} />}
    </section>
  )
}
