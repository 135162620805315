"use client"

import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import { sbSizeGuideSliceComponents } from "~/lib/storyblok/schemas/library/size-guide-slices"
import type { TSizeGuideSlice } from "~/components/slices/SizeGuideSlice/types"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

type SliceManagerProps = SliceWithProps<TSizeGuideSlice & RecipeVariants<typeof css.SizeGuideSlice>>

function SizeGuideSlice({ className, items, withGrid = true }: SliceManagerProps) {
  if (!items) return null

  return (
    <div className={clsx(className, css.SizeGuideSlice({ withGrid }))}>
      <div className={clsx(css.container)}>
        {items.map((slice, index) => {
          if (!slice) return null

          const SliceComponent = sbSizeGuideSliceComponents[slice.name]

          if (!SliceComponent || !slice.data) return null

          return (
            //@ts-ignore
            <SliceComponent
              key={`${slice.name}-${index}`}
              isFirstSlice={index === 0}
              isLastSlice={index === items?.length - 1}
              {...slice.data}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SizeGuideSlice
