import type { z } from "zod"

import { sbRichtextSchema } from "~/lib/storyblok/schemas/default/richtext"
import { sbComponent, sbSlice } from "~/lib/storyblok/schemas/helpers"
import type { TSizeGuideParagraph } from "~/components/slices/SizeGuideSlice/SizeGuideParagraph"

export function sbSliceSizeGuideParagraphSchema() {
  return sbSlice(
    sbComponent("size_guide_paragraph").extend({
      paragraph: sbRichtextSchema(),
    }),
    (data): TSizeGuideParagraph => {
      return {
        paragraph: data.paragraph,
      }
    }
  )
}

export type SbSizeGuideParagraph = z.infer<ReturnType<typeof sbSliceSizeGuideParagraphSchema>>
