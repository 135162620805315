import type { z } from "zod"

import type { SbSchemaOptions } from "~/lib/storyblok/schemas"
import { sbAssetImageSchema } from "~/lib/storyblok/schemas/default/assets/image"
import { sbComponent, sbSlice } from "~/lib/storyblok/schemas/helpers"
import type { TSizeGuideImage } from "~/components/slices/SizeGuideSlice/SizeGuideImage"

export function sbSliceSizeGuideImageSchema(options: SbSchemaOptions) {
  return sbSlice(
    sbComponent("size_guide_image").extend({
      image: sbAssetImageSchema(options),
    }),
    (data): TSizeGuideImage | null => {
      if (!data.image) return null

      return {
        image: data.image,
      }
    }
  )
}

export type SbSizeGuideImage = z.infer<ReturnType<typeof sbSliceSizeGuideImageSchema>>
