"use client"

import { useRef } from "react"
import { clsx } from "clsx"

import Slider from "@unlikelystudio/react-slider"

import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import { Image } from "~/components/ui/Image"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import { Link } from "~/components/ui/Link"
import type { TLink } from "~/components/ui/Link/_data/serializer"
import RichText from "~/components/ui/RichText"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 3 }, { ratio: 1 }]

export type TTriptyqueSquaredItem = {
  image: TImage
  text: SbRichtext
  textColor?: string
  link?: TLink
}

export type TTriptyqueSquared = {
  items: TTriptyqueSquaredItem[]
}
export type Diptich2Props = SliceWithProps<TTriptyqueSquared>

function TriptyqueSquared({ className, items }: Diptich2Props) {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div data-comp="Slices/TriptyqueSquared" ref={containerRef} className={css.TriptyqueSquaredContainer}>
      <div className={clsx(className, css.TriptyqueSquared({ type: "grid" }))}>
        {items.map((item, index) => (
          <Link key={index} className={css.item()} {...item.link}>
            <Image {...item.image} sizesFromBreakpoints={SIZES} ratio={"1_1"} />
            <RichText className={css.texte} render={item.text} style={{ color: item.textColor }} />
          </Link>
        ))}
      </div>
      <Slider
        className={clsx(className, css.TriptyqueSquared({ type: "slider" }))}
        customSliderRef={containerRef}
        snap
        autoBlockSlideIndexChange
      >
        {items.map((item, index) => (
          <Link key={index} className={css.item({ type: "slider" })} {...item.link}>
            <Image {...item.image} sizesFromBreakpoints={SIZES} ratio={"1_1"} />
            <RichText className={css.texte} render={item.text} style={{ color: item.textColor }} />
          </Link>
        ))}
      </Slider>
    </div>
  )
}

export default TriptyqueSquared
