import type { z } from "zod"

import { sbRichtextSchema } from "~/lib/storyblok/schemas/default/richtext"
import { sbComponent, sbSlice } from "~/lib/storyblok/schemas/helpers"
import type { TSizeGuideTitle } from "~/components/slices/SizeGuideSlice/SizeGuideTitle"

export function sbSliceSizeGuideTitleSchema() {
  return sbSlice(
    sbComponent("size_guide_title").extend({
      title: sbRichtextSchema(),
    }),
    (data): TSizeGuideTitle => {
      return {
        title: data.title,
      }
    }
  )
}

export type SbSizeGuideTitle = z.infer<ReturnType<typeof sbSliceSizeGuideTitleSchema>>
